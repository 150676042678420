// src/App.js
import React from "react";
import { useState, useRef } from "react";
import "./App.css";
import Pantry from "./Pages/Pantry";
import Recipe from "./Pages/Recipe";
import NavBar from "./Components/navBar";
import MainPage from "./Pages/MainPage";
import Contact from "./Pages/Contact";

function App() {
  const [recipeGenerated, setRecipeGenerated] = useState(
    "You have not generated a recipe yet!"
  );
  const [loading, setLoading] = useState(false);
  const test2Ref = useRef(null);
  const test1Ref = useRef(null);
  const [ingredients, setIngredients] = useState([])

  const SmoothScrollDown = () => {
    test2Ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const recipeFinishedGenerating = (newRecipe) => {
    setLoading(false);
    setRecipeGenerated(newRecipe);
  };

  const toggleLoading = () => {
    setLoading(true);
  };

  return (
    <div className="app">
      <NavBar>

      </NavBar>
      
     <MainPage></MainPage>
     <Pantry ingredientList={setIngredients}></Pantry>
     <Recipe ingredientList={ingredients}></Recipe>

     <Contact></Contact>
    </div>
  );
}

export default App;
