// src/Pantry.js
import React, { useState, useEffect } from "react";
import Searchbar from "../Components/Searchbar";
import ingredientData from "../Assets/csvjson_1.json";
import OpenAI from "openai";
import PopUp from "../Components/PopUp";
import TextRow from "../Components/TextRow";
import { Toaster } from "sonner";
import Sidebar from "../Components/Sidebar";
import "./Pantry.css";
import logo from "../Assets/cookwhite1.png"; // Adjust the path based on your project structure

const Pantry = ({
  ingredientList,
  scrollDown,
  recipeFinished,
  toggleLoading,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [recipeData, setRecipeData] = useState(null);
  const [error, setError] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [pantryVisible, setPantryVisible] = useState(false);
  const [isAbout, setIsAbout] = useState(false);
  const[isAutoScrolling, setAutoScrolling] = useState(false);

  const handleSelectedItems = (newIngredient) => {
    const updatedArray = selectedItems.concat(newIngredient[0].Name);
    setSelectedItems(updatedArray);
    ingredientList(updatedArray);
  };

  const handleFileSubmit = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const fileContent = JSON.parse(e.target.result);

        const jsonStrings = fileContent.map((obj) => obj.Name);

        const concatenatedArray = selectedItems.concat(jsonStrings);
        setSelectedItems(concatenatedArray);
        ingredientList(concatenatedArray);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };
    reader.readAsText(file);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
    ingredientList(updatedItems);
  };

  const undoClick = (ingredient) => {
    selectedItems.splice(selectedItems.indexOf(ingredient), 1);
    setSelectedItems(selectedItems);
    ingredientList(selectedItems);
  };

    
  useEffect(() => {
    
      const handleScroll = () => {
        closePantry();
      };
    
      window.addEventListener('scroll', handleScroll);
    
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    
   
  }, []);
  

  const closePantry = () => {
    setPantryVisible(false);
  };


  const togglePantry = () => {


    const pantryDiv = document.getElementById('Pantry');
      if (pantryDiv) {
        pantryDiv.scrollIntoView({ behavior: 'smooth' });
      }
      const timeout = setTimeout(() => {
        setPantryVisible(!pantryVisible);
      }, 500);

    
    
  };




  const toggleAbout = () => {
    setIsAbout(!isAbout);
  }

  const handleGenerateButtonClick = async (parameters) => {
    handleClosePopUp();
    scrollDown();

    //NOTE: parameters array is ordered like this: difficulty, type of meal, cuisine
    try {
      toggleLoading();
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const prompt4 = `Make me a ${parameters[0]} cooking recipe with: ${selectedItems}. Please try to make it a ${parameters[1]} recipe. The output will be displayed as text on an application, so the only response should be the recipe.`;

      const completion = await openai.chat.completions.create({
        model: "gpt-3.5-turbo", // or "gpt-3.5-turbo"
        messages: [{ role: "user", content: prompt4 }],
        temperature: 0.7, // Adjust as needed
        max_tokens: 350, // Adjust as needed
      });

      recipeFinished(completion.choices[0].message.content);
      setRecipeData(completion.choices[0].message.content);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleOpenPopUp = () => {
    setIsPopUpOpen(true);
  };

  const handleClosePopUp = () => {
    setIsPopUpOpen(false);
  };

  return (
    <div className="page-container" id="Pantry">
         <Sidebar
          pantryVisibility={pantryVisible}
          selectedItems={selectedItems}
          toggleClick={closePantry}
          onRemoveItem={handleRemoveItem}
        />
        <div class="pantrycontents">
          <h1> The Pantry </h1>
          <h4> Enter ingredients to get started</h4>
      

          <div className="input-container">
            

            <div className="search-container">
              <Searchbar
                placeholder="Enter your available ingredients"
                data={ingredientData}
                onSaveIngredient={handleSelectedItems}
                undoItem={undoClick}
                itemList={selectedItems}
              />

              <button onClick={togglePantry} className="pantry-buttons">
                View Pantry
              </button>
       
            
            </div>
            <Toaster position="top-center" expand={false} richColors />
          </div>

         

      
        

        </div>
      
       
        <TextRow
          selectedItems={selectedItems}
          fileSubmit={handleFileSubmit}
          togglePantry={togglePantry}
        />

   
     
    </div>
  );
};

export default Pantry;
