import React, { useState } from "react";
import { Link } from 'react-scroll';
import "./navBar.css";
import logo from "../Assets/cookwhite1.png"; 
// PopUp component
const NavBar = ({}) => {

const toggleMenu = () => {
    const menu = document.querySelector(".menu-links");
    const icon = document.querySelector(".hamburger-icon");
    menu.classList.toggle("open");
    icon.classList.toggle("open");
};
   
  return (
    <div>
        <nav id="desktop-nav">
       
        <div class="logo">
           
            <span>Let Us Cook!</span>
        </div>


        <div>
        <ul class="nav-links">
         
          <li><Link to="Pantry" smooth={true} duration={500}>Pantry</Link></li>
          <li><Link to="Generate" smooth={true} duration={700}>Generate</Link></li>
          <li><Link to="Contact" smooth={true} duration={900}>Contact</Link></li>
        </ul>
        </div>
    </nav>

    <nav id="hamburger-nav">
      <div class="logo">Let Us Cook!</div>

      
      <div class="hamburger-menu">
        <div class="hamburger-icon" onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="menu-links">
          <li><Link to="Pantry" smooth={true} duration={500}>Pantry</Link></li>
            <li><Link to="Generate" smooth={true} duration={700}>Generate</Link></li>
            <li><Link to="Contact" smooth={true} duration={900}>Contact</Link></li>
        </div>
      </div>
    </nav>
    </div>
    

  
  );
};

export default NavBar;
