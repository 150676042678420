import React from "react";
import download from "../Assets/download.png";
import "./downloadButton.css";
const DownloadButton = ({ dataArray }) => {
  const downloadJsonFile = () => {
    // Add a "Name" field to each item in the array
    const jsonArray = dataArray.map((item) => ({ Name: item }));

    // Convert the array of objects to a JSON string
    const jsonString = JSON.stringify(jsonArray, null, 2);

    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "YourPantry" || "data.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <button className="download-button" onClick={downloadJsonFile}>
      Download
    </button>
  );
};

export default DownloadButton;
