import React from 'react';
import './TextRow.css'; 
import UploadCSV from './UploadCSV.js';
import DownloadButton from './downloadButton.js';


const TextRow = ({fileSubmit, selectedItems, togglePantry }) => {
  return (
    <div className="text-row">
      <div className="text-item">
        <h2> Not your first time?</h2>
        <p> Upload your previous pantry file here.</p>
        <UploadCSV onSubmitFile={fileSubmit}/>
      </div>
      <div className="text-separator"></div>
      <div className="text-item">
        <h2> Coming back? </h2>
        <p> Download the state of the pantry.</p>
       <DownloadButton dataArray={selectedItems}/>
      </div>
     
    </div>
  );
};

export default TextRow;
