import React, { useState } from "react";
import "./Sidebar.css"; // Create a separate CSS file for styling

const Sidebar = ({
  pantryVisibility,
  selectedItems,
  onRemoveItem,
  toggleClick,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleSidebar = () => {
    toggleClick();
  };

  return (
    <div className={pantryVisibility ? "visible" : "none"}>
      <div className="closeBtn">
        <button className="close-button" onClick={toggleSidebar}>Close</button>
      </div>
  
      {selectedItems.length === 0 ? (
        <h1 class="pantry-title">No ingredients in pantry!</h1>
      ) : (
        <ul>
          {selectedItems.map((value, index) => (
            <button className="item-button" key={index} onClick={() => onRemoveItem(index)}>
              {value}
            </button>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Sidebar;
