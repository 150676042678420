import React from 'react';
import './Contact.css';
import contactBackground from '../Assets/cookingvideo.mp4'
const Contact = () => {
  return (
    <div className="contact" id="Contact">
      
      <div className="content2">
        <h2 id='top-title'> Have Questions? </h2>
        <h1>Contact</h1>
        <div className="button-container2">
          <a href='https://linktr.ee/letuscook' target='_blank'> 
            <button className="button2">Message</button>
          </a>
          
        </div>
      </div>
     
    

    </div>
  );
}

export default Contact;