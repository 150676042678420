import React, { useState, useRef } from "react";
//import uploadGIF from "../Assets/uploadGIF.gif";
//import downloadPNG from "../Assets/downloadPNG.png";

import './UploadCSV.css'; 

const UploadCSV = ({ onSubmitFile }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileValid, setIsFileValid] = useState(true);
  const [isDLHovered, setDLHovered] = useState(false);
  const [isULHovered, setULHovered] = useState(false);
  const [isVPHovered, setVPHovered] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type === "application/json") {
        setSelectedFile(file);
        setIsFileValid(true);
      } else {
        setIsFileValid(false);
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = () => {
    onSubmitFile(selectedFile);
    setSelectedFile(null);
    
  };

  return (
    <div id="fileUploadContainer">
      <input
        type="file"
        accept=".json"
        onChange={handleFileChange}
        style={{ display: "none" }}
        ref={fileInputRef}
        id="fileInput"
      />

      {selectedFile && isFileValid && (
        <button id="submitBtn" className="upload-button" onClick={handleSubmit}>
          Submit
        </button>
      )}

      <div>
        {selectedFile ? (
          <div>
            {isFileValid ? (
              <p id="fileInfo">Selected file: {selectedFile.name}</p>
            ) : (
              <p id="errorMessage">
                Invalid file format. Please select a JSON file.
              </p>
            )}
          </div>
        ) : (
          <button className="upload-button" onClick={handleButtonClick}>
            Upload
          </button>
        )}
      </div>
    </div>
  );
};

export default UploadCSV;
