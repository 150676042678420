// src/Recipe.js
import React, { useState } from "react";
import Searchbar from "../Components/Searchbar";
import ingredientData from "../Assets/csvjson_1.json";
import OpenAI from "openai";
import { Hourglass } from "react-loader-spinner";
import "./Recipe.css";

const Recipe = ({ingredientList}) => {
  const [answer1, setAnswer1] = useState("Easy");
  const [answer2, setAnswer2] = useState("Dinner");
  const [recipe, setRecipe] = useState("");
  const [loading, setLoading] = useState(false);
  const [emptyError, setEmptyError] = useState(false);



  const handleAnswer1Change = (event) => {
    setAnswer1(event.target.value);
  };

  const handleAnswer2Change = (event) => {
    setAnswer2(event.target.value);
  };

  const generationHandler = (event) => {
    if (ingredientList.length === 0){
      setEmptyError(true);
    }
    else{
      setEmptyError(false);
      generateRecipe();
    }
  };


 const generateRecipe = async (event) => {
    console.log(ingredientList)
    setLoading(true);
    try {
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const prompt4 = `Make me a ${answer1} cooking recipe with: ${ingredientList}. Please try to make it a ${answer2} recipe. The output will be displayed as text on an application, so the only response should be the recipe.`;

      const completion = await openai.chat.completions.create({
        model: "gpt-3.5-turbo", // or "gpt-3.5-turbo"W
        messages: [{ role: "user", content: prompt4 }],
        temperature: 0.7, // Adjust as needed
        max_tokens: 750, // Adjust as needed
      });

      console.log(completion.choices[0].message.content);
      setRecipe(completion.choices[0].message.content);
      setLoading(false);
    } catch (error) {
      console.log("Error");
      setLoading(false);
    }
  };

  return (
    <div className="recipe-container" id="Generate">
      <h2> Generate Recipe </h2>

      <div class="recipe-question-container">
        <p>
          How advanced should the recipe be?
          <select value={answer1} onChange={handleAnswer1Change}>
            <option value="">Select an answer</option>
            <option value="easy">Easy</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          
          </select>
        </p>

        <p>
          What type of meal should the recipe be?
          <select value={answer2} onChange={handleAnswer2Change}>
            <option value="">Select an answer</option>
            <option value="Breakfast">Breakfast</option>
            <option value="Lunch/Dinner">Lunch/Dinner</option>
            <option value="Dessert">Dessert</option>
            <option value="Snack">Snack</option>
            <option value="Any">Any</option>

          </select>
        </p>

        
        <button onClick={generationHandler} className="pantry-buttons">
                Generate Recipe
        </button>

        <div>
          {emptyError && <p style={{ color: 'red' }}>Error: Please Enter ingredients.</p>}
        </div>



      </div>

      <div className="recipe-text" style={{ whiteSpace: "pre-line" }}>
        {loading ? (
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["black", "black"]}
          />
        ) : (
          <div className={`recipe-box ${recipe === "" ? "no-border" : ""}`}>
            <p>{recipe}</p>
          </div>

        
        )}
      </div>

    

      
    </div>
  );
};

export default Recipe;
