import React, { useState } from "react";
import "./Searchbar.css";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "sonner";

function Searchbar({
  placeholder,
  data,
  onSaveIngredient,
  undoItem,
  itemList,
}) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const handleFilter = (event) => {
    const searchWord =
      event.target.value; /*word user currently trying to search for */
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.Name.toLowerCase().includes(searchWord.toLowerCase());
    });
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const handleItemClick = (item) => {
    const isDuplicate = itemList.some(
      (selectedItem) => selectedItem === item.Name
    );

    if (!isDuplicate) {
      selectedItems.push(item);
      onSaveIngredient(selectedItems);
      toastNoti(item);
    } else {
      toast.warning(item.Name + " is already in your pantry", {});
    }

    setFilteredData([]);
    setWordEntered("");
    setSelectedItems([]);
  };

  const toastNoti = (item) => {
    toast.success(item.Name + " has been added to your pantry", {});
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <div className="search">
      <div className="searchInputs">
        <input
          className="place-text"
          type="text"
          placeholder={placeholder}
          onChange={handleFilter}
          value={wordEntered}
        />
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div className="dataResult">
          {filteredData.map((value, key) => {
            return (
              <a
                key={key}
                className="dataItem"
                onClick={() => {
                  handleItemClick(value);
                }}
              >
                <p>{value.Name} </p>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Searchbar;
